import React from "react";
import Box from "theme/components/atoms/Layout/Box";
import { H5 } from "theme/components/atoms/Typography/Heading";
import PropTypes from "prop-types";

const RecapCard = ({ children, title, titleDescription, description }) => {
  return (
    <Box>
      {(title || titleDescription) && (
        <div className="recap-card__title">
          {title && <H5 as="h3">{title}</H5>}
          {titleDescription}
        </div>
      )}
      {description && (
        <div className="recap-card__description">{description}</div>
      )}
      <div className="recap-card__children">{children}</div>
    </Box>
  );
};

RecapCard.propTypes = {
  title: PropTypes.string.isRequired,
  titleDescription: PropTypes.node,
  children: PropTypes.node,
  description: PropTypes.node,
};

export default RecapCard;
