import React from "react";
import PropTypes from "prop-types";
import withProps from "recompose/withProps";
import compose from "recompose/compose";
import classNames from "classnames";
import withFormHandlers from "../withFormHandlers";
import BaseInput from "../BaseInput";
import { defineMessages, injectIntl } from "react-intl";
import Button from "theme/components/atoms/Button";

const messages = defineMessages({
  invalidInteger: {
    id: "components.atoms.Form.Input.NumberInput.invalidInteger",
    defaultMessage: "This field must be a number",
  },
  decrement: {
    id: "components.atoms.Form.Input.NumberInput.decrement",
    defaultMessage: "Decrease",
  },
  increment: {
    id: "components.atoms.Form.Input.NumberInput.increment",
    defaultMessage: "Increase",
  },
});

const NumberInput = (props) => {
  const {
    getErrors,
    isPristine,
    isValid,
    isDirty,
    appearance,
    baseInputProps,
    autoComplete,
    autocomplete,
    onChange,
    id,
    name,
    value,
    intl,
    disabled,
    ...rest
  } = props;

  const classes = classNames("input-number", {
    "input--invalid": getErrors().length,
    "input--valid": isValid(),
    [`input--${props.inputSize}`]: props.inputSize,
  });

  const errors = getErrors();

  return (
    <BaseInput
      appearance={appearance}
      input={
        <div
          className={classNames("number-input", {
            [`number-input--${appearance}`]: appearance,
          })}
        >
          <div className="number-input__button">
            <Button
              title={intl.formatMessage(messages.decrement)}
              onClick={(event) => {
                event.preventDefault();
                onChange(value - 1);
              }}
              iconSize={appearance === "vertical" ? "mini" : undefined}
              state={value <= 0 || disabled ? "disabled" : undefined}
            >
              -
            </Button>
          </div>
          <div className="number-input__input">
            <input
              className={classes}
              name={name}
              id={id}
              type="number"
              value={value}
              onChange={(e) => onChange(e.target.value)}
              disabled={disabled}
              {...rest}
            />
          </div>
          <div className="number-input__button">
            <Button
              title={intl.formatMessage(messages.increment)}
              onClick={(event) => {
                event.preventDefault();
                onChange(value + 1);
              }}
              iconSize={appearance === "vertical" ? "mini" : undefined}
              state={disabled ? "disabled" : undefined}
            >
              +
            </Button>
          </div>
        </div>
      }
      help={props.help}
      errors={errors}
      {...props.baseInputProps}
    />
  );
};

NumberInput.propTypes = {
  appearance: PropTypes.oneOf(["default", "vertical"]),
};

export default compose(
  injectIntl,
  withProps((props) => ({
    validations: {
      isInt: true,
      ...(typeof props.validations === "string"
        ? { [props.validations]: true }
        : props.validations || {}),
    },
    validationErrors: {
      isInt: props.intl.formatMessage(messages.invalidInteger),
      ...(props.validationErrors || {}),
    },
  })),
  withFormHandlers({
    getValueFromEvent: (value) => {
      if (value.length === 0) {
        return null;
      } else {
        let parsedValue = parseFloat(value);
        if (isNaN(parsedValue)) {
          parsedValue = null;
        } else if (parsedValue < 0) {
          parsedValue = 0;
        }
        return parsedValue;
      }
    },
    getValueFromProps: ({ value }) => value,
    getPropsFromValue: (value) => {
      if (value === null || value === "") {
        value = "";
      } else {
        const parsedValue = Number(value);
        if (!isNaN(parsedValue) && parsedValue < 0) {
          value = 0;
        }
      }

      return { value: value };
    },
  })
)(NumberInput);
