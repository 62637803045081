import { defineMessages } from "react-intl";
import passwordConfig from "./passwordConfig";
import { hasPasswordStrengthHint } from "../PasswordStrengthHint/PasswordStrengthHint";

const MIN_PASSWORD_LENGTH = 6;
const MIN_CHAR_CLASSES = 0;

function validatePassword(password = "", config = passwordConfig) {
  const { rules, status } = config;

  let validRules = 0;
  let displayedStatus = null;

  const processedRules = rules.map((rule) => {
    const valid = rule.isValid(password);

    if (valid) {
      validRules++;
    } else if (rule.invalidStatus) {
      displayedStatus = status[rule.invalidStatus];
    }

    return {
      ...rule,
      valid,
    };
  });

  if (!displayedStatus) {
    displayedStatus = Object.values(status)
      .filter((status) => {
        return (
          status.minCriterias !== undefined && status.minCriterias <= validRules
        );
      })
      .sort((s1, s2) => s2.minCriterias - s1.minCriterias)[0];
  }

  return {
    rules: processedRules.filter((rule) => rule.label),
    status: {
      ...displayedStatus,
      progress: Math.floor((validRules / rules.length) * 100),
    },
    isValid: displayedStatus.isValid,
  };
}

const messages = defineMessages({
  passwordError: {
    id: "components.organisms.Register.RegisterForm.passwordError",
    defaultMessage:
      "The password must contain a minimum of {minLength} characters, {minClasses} of which are: lowercase, uppercase, digit, special character",
  },
  passwordNotMatchingError: {
    id: "components.organisms.Register.RegisterForm.passwordNotMatchingError",
    defaultMessage: "Passwords do not match",
  },
  notStrongPassword: {
    id: "components.atoms.Form.Input.PasswordStrengthHint.notStrongEnoughError",
    defaultMessage: "The current password is not strong enough",
  },
});

const errorMessage = messages.passwordError;
const passwordNotMatchingErrorMessage = messages.passwordNotMatchingError;

function passwordValidationRules(config) {
  return {
    magentoPasswordRule: (_, value) => true,
  };
}

/**
 * @param {import('react-intl/src/types').IntlShape} intl
 * @param {"STANDALONE"|"HINTER"} mode message mode, defaults to STANDALONE
 * @returns
 */
function passwordValidationErrors(intl, mode) {
  if (!hasPasswordStrengthHint()) {
    // enforces a self-sufficient message when PasswordStrengthHint is disabled
    mode = "STANDALONE";
  }
  switch (mode) {
    case "HINTER":
      return intl.formatMessage(messages.notStrongPassword);
    case "STANDALONE":
    default:
      return intl.formatMessage(errorMessage, {
        minLength: MIN_PASSWORD_LENGTH,
        minClasses: MIN_CHAR_CLASSES,
      });
  }
}

export {
  validatePassword,
  passwordValidationRules,
  passwordValidationErrors,
  passwordNotMatchingErrorMessage,
};
