import React from "react";
import PropTypes from "prop-types";
import classNames from "classnames";
import { FormattedMessage } from "react-intl";

const Label = ({ children, htmlFor, required, hideOptionalLabel }) => {
  return (
    <label
      htmlFor={htmlFor}
      className={classNames({ "label--required": required })}
    >
      {children}
      {children &&
        typeof children === "string" &&
        !required &&
        !hideOptionalLabel && (
          <span className="label--optional">
            {" "}
            -{" "}
            <FormattedMessage
              id="components.atoms.Form.Label.optional"
              defaultMessage="optional"
            />
          </span>
        )}
    </label>
  );
};

Label.propTypes = {
  children: PropTypes.node.isRequired,
  htmlFor: PropTypes.string.isRequired,
  required: PropTypes.bool,
  hideOptionalLabel: PropTypes.bool,
};

export default Label;
