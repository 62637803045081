import { branch } from "recompose";

const EnhanceLoadable = (
  isLoaded,
  Spinner,
  isFullPage,
  SpinnerFullPage = null
) =>
  branch(
    isLoaded,
    (BaseComponent) => BaseComponent,
    () => (isFullPage ? SpinnerFullPage : Spinner)
  );

export default EnhanceLoadable;
