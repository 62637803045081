import React from "react";
import classnames from "classnames";

export const PLACEHOLDER = "/images/resized/placeholder.png";
export const ERROR = "/images/resized/error.png";

export const transparentExtensions = ["webp", "png"];
export const extensions = ["webp", "jpeg"];
export const defaultExtension = "jpeg";

const Picture = ({ src, sources, alt, sizes, ...props }) => (
  <picture>
    {sources?.map((sourceProps, index) => (
      <source key={index} {...sourceProps} sizes={sizes} />
    ))}
    <img
      src={src}
      alt={alt}
      className={props.className}
      onClick={props.onClick}
    />
  </picture>
);

const ImageComponent = React.forwardRef(
  (
    {
      images,
      alt,
      sizes,
      appearance,
      cover,
      imgProps,
      ratio,
      status,
      gradient,
      dangerouslyDisableLazyLoad,
    },
    ref
  ) => {
    const classes = classnames("image responsive-image", {
      [`image--${appearance}`]: appearance,
      [`responsive-image--${appearance}`]: appearance,
      [`responsive-image--${status}`]: status,
      "image--cover": cover,
      "image--gradient": gradient,
    });

    return (
      <span
        ref={ref}
        className={classes}
        style={
          ratio
            ? { "--image-ratio": `${(ratio * 100).toFixed(0)}%` }
            : undefined
        }
      >
        <Picture
          {...imgProps}
          {...images[status]}
          className="responsive-image__actual"
          alt={alt}
          sizes={sizes}
        />
        {!dangerouslyDisableLazyLoad && (
          <Picture
            {...images.loading}
            className="responsive-image__placeholder"
            alt={status === "loading" ? "Loading" : ""}
            sizes={sizes}
          />
        )}
      </span>
    );
  }
);

export default ImageComponent;
