import React from "react";
import Input from "../Input";
import { compose, withState } from "recompose";
import IconButton from "theme/components/atoms/Button/IconButton";
import { injectIntl, defineMessages } from "react-intl";

const messages = defineMessages({
  showPassword: {
    id: "components.atoms.Form.Input.Password.showPassword",
    defaultMessage: "Show password",
  },
  hidePassword: {
    id: "components.atoms.Form.Input.Password.hidePassword",
    defaultMessage: "Hide password",
  },
});

const Password = ({ passwordVisible, togglePassword, ...props }) => (
  <div className="password-wrapper">
    <Input {...props} type={passwordVisible ? "text" : "password"} />
    <IconButton
      className="password-wrapper__toggle-password"
      type="button"
      icon={passwordVisible ? "eye_on" : "eye_off"}
      title={
        passwordVisible
          ? props.intl.formatMessage(messages.hidePassword)
          : props.intl.formatMessage(messages.showPassword)
      }
      onClick={() => togglePassword(!props.passwordVisible)}
    />
  </div>
);

export default compose(
  injectIntl,
  withState("passwordVisible", "togglePassword", false)
)(Password);
