import React from "react";
import PropTypes from "prop-types";
import { FormattedNumber, FormattedMessage } from "react-intl";
import invariant from "fbjs/lib/invariant";
import { currenciesAllowedList } from "theme/components/atoms/Typography/Money/Money";
import Money from "theme/components/atoms/Typography/Money";

/**
 * @deprecated use `currenciesAllowedList` instead
 */
export const currenciesWhitelist = currenciesAllowedList;
export { currenciesAllowedList };

const Price = ({ price: { value } }) => {
  let currency = value.currency ?? "EUR";
  invariant(
    currenciesAllowedList.indexOf(currency) > -1,
    `"${currency}" is not a whitelisted currency code`
  );

  return (
    <span className={`price`}>
      {value.amount === null ? (
        <FormattedMessage
          id="components.atoms.Typography.Price.notApplicable"
          defaultMessage="N/A"
        />
      ) : (
        <FormattedNumber
          value={value.amount}
          // eslint-disable-next-line react/style-prop-object
          style="currency"
          currency={currency}
        />
      )}
    </span>
  );
};

Price.propTypes = {
  price: PropTypes.shape({
    value: Money.propTypes.value,
  }),
};

export default Price;
