import React, { Fragment } from "react";
import PropTypes from "prop-types";
import classNames from "classnames";
import { FormattedMessage } from "react-intl";
import Price from "./Price";

const ProductPriceSeo = ({ prices }) => {
  return (
    <Fragment>
      <meta
        itemProp="price"
        content={prices.finalPrice.priceInclTax.value.amount.toFixed(2)}
      />
      <meta
        itemProp="priceCurrency"
        content={prices.finalPrice.priceInclTax.value.currency}
      />
    </Fragment>
  );
};

const TierPrice = ({ tierPrice }) => {
  if (!tierPrice || tierPrice.qty <= 1) {
    return null;
  }
  return (
    <div className="product-price__tier-price">
      <FormattedMessage
        id="components.atoms.Typography.Price.ProductPrice.from"
        defaultMessage="Buy {qty} for {price} each"
        values={{
          qty: tierPrice.qty,
          price: <Price price={tierPrice.price.priceInclTax} />,
        }}
      />
    </div>
  );
};

const ProductPrice = ({ prices, prefix, productPage = false }) => {
  if (!prices) {
    return null;
  }

  const { regularPrice, finalPrice, tierPrice = null } = prices;

  if (
    regularPrice.priceInclTax.value.amount <=
    finalPrice.priceInclTax.value.amount
  ) {
    return (
      <div
        className={classNames("product-price", {
          "product-price--product-page": productPage,
        })}
      >
        <ProductPriceSeo prices={prices} />
        <div className="product-price__price">
          {prefix && <span className="product-price__prefix">{prefix}</span>}
          <span className="product-price__regular">
            <Price price={finalPrice.priceInclTax} />
          </span>
        </div>
        <TierPrice tierPrice={tierPrice} />
      </div>
    );
  }

  return (
    <div
      className={classNames("product-price product-price--special", {
        "product-price--product-page": productPage,
      })}
    >
      <ProductPriceSeo prices={prices} />
      <div className="product-price__price">
        {prefix && <span className="product-price__prefix">{prefix}</span>}
        <span className="product-price__special">
          <Price price={finalPrice.priceInclTax} />
        </span>
        <span className="product-price__regular">
          <Price price={regularPrice.priceInclTax} />
        </span>
      </div>
      <TierPrice tierPrice={tierPrice} />
    </div>
  );
};

ProductPrice.propTypes = {
  productPage: PropTypes.bool,
  prices: PropTypes.shape({
    regularPrice: PropTypes.shape({
      priceInclTax: Price.propTypes.price,
      priceExclTax: Price.propTypes.price,
    }).isRequired,
    finalPrice: PropTypes.shape({
      priceInclTax: Price.propTypes.price,
      priceExclTax: Price.propTypes.price,
    }).isRequired,
    tierPrice: PropTypes.shape({
      qty: PropTypes.number.isRequired,
      price: PropTypes.shape({
        priceInclTax: Price.propTypes.price,
        priceExclTax: Price.propTypes.price,
      }).isRequired,
    }),
  }).isRequired,
  prefix: PropTypes.node,
};

export default ProductPrice;
