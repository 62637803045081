import { compose, withProps } from "recompose";
import { graphql } from "react-apollo";
import { defineMessages, injectIntl } from "react-intl";
import IsoCountries from "i18n-iso-countries";

const messages = defineMessages({
  countryPlaceholder: {
    id: "components.atoms.Form.Input.CountrySelect.witchCountries.Placeholder",
    defaultMessage: "Select your country...",
  },
});

const withCountries = ({ CountriesQuery }) =>
  compose(
    injectIntl,
    graphql(CountriesQuery, {
      props: ({
        data,
        ownProps: {
          intl: { locale, defaultLocale },
        },
      }) => {
        return {
          baseCountries: data.loading
            ? []
            : data.countryList.map((country) => ({
                value: country,
                label:
                  IsoCountries.getName(country, locale) ||
                  IsoCountries.getName(country, defaultLocale) ||
                  country,
              })),
        };
      },
    }),
    withProps((props) => {
      if (props.baseCountries && props.baseCountries.length) {
        return {
          countries: [
            {
              value: "",
              label: props.intl.formatMessage(messages.countryPlaceholder),
            },
          ].concat(props.baseCountries),
        };
      } else {
        return { countries: [] };
      }
    })
  );

export default withCountries;
