import React from "react";
import PropTypes from "prop-types";
import classNames from "classnames";
import Icon from "theme/components/atoms/Icon";
import deprecateRenamedProperty from "web/core/dx/deprecateRenamedProperty";

const mapping = {
  success: "check",
  error: "attention",
  info: "chevron-right-circle",
};

const appearanceToIcon = (appearance) => {
  if (!Object.prototype.hasOwnProperty.call(mapping, appearance)) {
    console.warn(`No icon referenced in 'Alert' component for '${appearance}'`);
    return;
  }

  return mapping[appearance];
};

const Alert = ({ type, appearance, children }) => {
  if (!appearance) {
    appearance = deprecateRenamedProperty("Alert", "type", "appearance", type);
  }

  return (
    <div className={classNames("alert", `alert--${appearance}`)}>
      <div className="alert__content">
        <Icon icon={appearanceToIcon(appearance)} title="" />
        <div className="alert__content__message">{children}</div>
      </div>
    </div>
  );
};

Alert.propTypes = {
  type: PropTypes.oneOf(["success", "error", "info"]),
  appearance: PropTypes.oneOf(["success", "error", "info"]).isRequired,
  children: PropTypes.node,
};

export default Alert;
