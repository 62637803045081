import React from "react";
import PropTypes from "prop-types";
import Select from "../Select";
import withCountries from "./withCountries";
import CountriesQuery from "./CountriesQuery.gql";

const CountrySelect = (props) => {
  return <Select isSearchable={true} {...props} options={props.countries} />;
};

CountrySelect.propTypes = {
  countries: PropTypes.array.isRequired,
};

export default withCountries({ CountriesQuery })(CountrySelect);
