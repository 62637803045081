import React from "react";
import PropTypes from "prop-types";
import classNames from "classnames";
import Label from "theme/components/atoms/Form/Label";

const Item = ({
  label,
  children,
  inline,
  inverted,
  required,
  name,
  hideOptionalLabel,
}) => {
  required =
    typeof required === "undefined" && React.isValidElement(children)
      ? children.props.required
      : required;

  name =
    typeof name === "undefined" && React.isValidElement(children)
      ? children.props.id || children.props.name
      : name;

  const classes = classNames({
    [`form-item`]: true,
    [`form-item--inline`]: inline,
    [`form-item--inverted`]: inverted,
    required,
  });

  return (
    <div className={classes}>
      <div className="form-item__label">
        <Label
          required={required}
          htmlFor={name}
          hideOptionalLabel={hideOptionalLabel}
        >
          {label}
        </Label>
      </div>
      <div className="form-item__input">{children}</div>
    </div>
  );
};

Item.propTypes = {
  label: Label.propTypes.children,
  children: PropTypes.node.isRequired,
  inline: PropTypes.bool,
  inverted: PropTypes.bool,
  required: PropTypes.bool,
  name: PropTypes.string,
};

export default Item;
