module.exports = {
  defaultBgColor: "FFFFFF",
  presets: {
    thumbnail: { width: 50, height: 50, bgColors: [] },
    thumbnailMediaGalery: { width: 159, height: 159, bgColors: [] },
    smallListing: { width: 247, height: 247, bgColors: [] },
    themeFullImage: { width: 812, height: 812, bgColors: [] },
    lookbookWidgetDesktop: { width: 750, height: 853, bgColors: [] },
    small: { width: 280, height: 280, bgColors: [] },
    medium: { width: 474, height: 474, bgColors: [] },
    square: { width: 700, height: 700, bgColors: [] },
    productMainImage: { width: 600, height: 600, bgColors: [] },
    large: { width: 1100, height: 1100, bgColors: [] },
    full: { width: 1422, height: 1422, bgColors: [] },
    threeImages: { width: 928, height: 1270, bgColors: [] },
    threeImagesMobile: { width: 576, height: 640, bgColors: [] },
    hero: { width: 1500, height: 624, bgColors: [] },
    heroTablet: { width: 991, height: 470, bgColors: [] },
    heroMobile: { width: 1200, height: 940, bgColors: [] },
    push: { width: 630, height: 715, bgColors: [] },
    categoryFullscreen: { width: 1500, height: 550, bgColors: [] },
    categoryFullscreenMobile: { width: 640, height: 400, bgColors: [] },
    backgroundForm: { width: 1900, height: 660, bgColors: [] },
    otherLookbook: { width: 440, height: 640, bgColors: [] },
    otherLookbookMobile: { width: 576, height: 400, bgColors: [] },
    lookbookThumbnail: { width: 318, height: 356, bgColors: [] },
    newsThumbnailBig: { width: 684, height: 684, bgColors: [] },
    newsThumbnailSmall: { width: 342, height: 342, bgColors: [] },
    imageParallaxSmall: { width: 928, height: 1270, bgColors: [] },
    imageParallaxSmallMobile: { width: 366, height: 500, bgColors: [] },
    imageParallaxLarge: { width: 1172, height: 1604, bgColors: [] },
    imageParallaxLargeMobile: { width: 435, height: 596, bgColors: [] },
    fourEntriesMobile: { width: 230, height: 436, bgColors: [] },
    fourEntries: { width: 746, height: 1026, bgColors: [] },
    productPush: { width: 684, height: 620, bgColors: [] },
    productPushMobile: { width: 576, height: 522, bgColors: [] },
  },
};
