import React, { useState, useEffect, useMemo, useRef } from "react";
import { compose, withProps } from "recompose";
import PropTypes from "prop-types";
import { presets } from "config/images";
import { useInView } from "react-intersection-observer";
import withGenderFilter from "theme/components/helpers/withGenderFilter";
import ImageComponent, {
  ERROR,
  extensions,
  transparentExtensions,
  defaultExtension,
} from "./ImageComponent";
import makeImgSrc from "./makeImageSrc";
const PLACEHOLDER =
  "data:image/png;base64,iVBORw0KGgoAAAANSUhEUgAAAAEAAAABCAQAAAC1HAwCAAAAC0lEQVR42mN8+R8AAtcB6oaHtZcAAAAASUVORK5CYII=";
const sourceMediabyType = {
  large: "(min-width: 991px)",
  medium: "(min-width: 600px)",
  small: "(min-width: 300px)",
};
const typeOrder = ["large", "medium", "small"];

const byType = (typeA, typeB) => {
  return typeOrder.indexOf(typeA) - typeOrder.indexOf(typeB);
};

const makeImageSrcset = (src, format, sizes, bg, extension, cover, fluid) => {
  return sizes
    .map((size) => {
      const imageUrl = makeImgSrc(src, format, size, bg, extension, cover);
      const imageSet = fluid ? `${presets[format].width * size}w` : `${size}x`;
      return `${imageUrl} ${imageSet}`;
    })
    .join(", ");
};

const makeImgProps = (
  src,
  format,
  bg,
  cover,
  fluid,
  srcset = null,
  srcsetFormat = null
) => {
  if (!presets[format]) {
    return {
      src: src,
      sources: srcset
        ? Object.keys(srcset)
            .filter((key) => typeOrder.indexOf(key) > -1)
            .sort(byType)
            .map((type) => ({
              srcSet: srcset[type],
              media: sourceMediabyType[type],
            }))
        : undefined,
    };
  }

  const sizes = presets[format].sizes || [1];
  const sourcesExtensions =
    bg === "transparent" ? transparentExtensions : extensions;

  return {
    src: makeImgSrc(src, format, sizes[0], bg, defaultExtension, cover),
    sources: sourcesExtensions
      .map((extension) => {
        return srcset
          ? Object.keys(srcset)
              .filter((key) => typeOrder.indexOf(key) > -1)
              .sort(byType)
              .map((type) => ({
                type: `image/${extension}`,
                srcSet: makeImageSrcset(
                  srcset[type],
                  srcsetFormat && srcsetFormat[type]
                    ? srcsetFormat[type]
                    : format,
                  sizes,
                  bg,
                  extension,
                  cover,
                  fluid
                ),
                media: sourceMediabyType[type],
              }))
          : [
              {
                type: `image/${extension}`,
                srcSet: makeImageSrcset(
                  src,
                  format,
                  sizes,
                  bg,
                  extension,
                  cover,
                  fluid
                ),
              },
            ];
      })
      .reduce((acc, item) => {
        return acc.concat(...item);
      }, []),
  };
};

const makeImagesProps = (
  src,
  srcset,
  srcsetFormat,
  format,
  bg,
  cover,
  fluid,
  placeholderSrc = PLACEHOLDER,
  errorSrc = ERROR
) => {
  return {
    done: makeImgProps(
      src || errorSrc,
      format,
      bg,
      cover,
      fluid,
      srcset,
      srcsetFormat
    ),
    loading: { src: placeholderSrc, sources: [] },
    error: makeImgProps(errorSrc, format, bg, true, fluid),
  };
};

const imagesCache = {};
const useImageStatus = (images, dangerouslyDisableLazyLoad) => {
  let ref, inView;
  /* eslint-disable react-hooks/rules-of-hooks */
  if (!process.env.SERVER && window.IntersectionObserver) {
    const inViewResult = useInView({
      triggerOnce: true,
      margin: "200px 0px",
    });
    ref = inViewResult[0];
    inView = inViewResult[1];
  } else {
    ref = useRef();
    inView = true;
  }
  /* eslint-enable react-hooks/rules-of-hooks */

  const [state, setStatus] = useState({
    status:
      dangerouslyDisableLazyLoad || imagesCache[images.done.src]
        ? "done"
        : "loading",
    images: images,
  });

  useEffect(() => {
    setStatus((state) => {
      if (
        images.done.src === state.images.done.src &&
        !dangerouslyDisableLazyLoad
      ) {
        return state;
      }

      return {
        status:
          dangerouslyDisableLazyLoad || imagesCache[images.done.src]
            ? "done"
            : "loading",
        images: images,
      };
    });
  }, [images, dangerouslyDisableLazyLoad]);

  useEffect(() => {
    if (dangerouslyDisableLazyLoad || !inView || state.status !== "loading") {
      return;
    }

    const loadHandler = () => {
      imagesCache[images.done.src] = true;
      setStatus((state) => ({ ...state, status: "done" }));
    };
    const errorHandler = () => {
      setStatus((state) => ({ ...state, status: "error" }));
    };

    const img = document.createElement("img");
    img.setAttribute("src", images.done.src);
    if (images.done.srcSet) {
      img.setAttribute("srcset", images.done.srcSet);
    }

    img.addEventListener("load", loadHandler);
    img.addEventListener("error", errorHandler);

    return () => {
      img.removeEventListener("load", loadHandler);
      img.removeEventListener("error", errorHandler);
    };
  }, [images, inView, state, dangerouslyDisableLazyLoad]);

  return {
    imageRef: ref,
    status: state.status,
  };
};

const Image = ({
  src = "/",
  srcset = null,
  srcsetFormat = null,
  alt,
  format,
  bg,
  cover,
  appearance,
  dangerouslyDisableLazyLoad,
  placeholderSrc,
  errorSrc,
  className,
  gradient,
  sizes,
  ...imgProps
}) => {
  /* eslint-disable */
  const images = useMemo(
    () =>
      makeImagesProps(
        src,
        srcset,
        srcsetFormat,
        format,
        bg,
        cover,
        appearance.indexOf("full") > -1,
        placeholderSrc,
        errorSrc
      ),
    [src, format, bg, cover, appearance, placeholderSrc, errorSrc]
  );

  const { imageRef, status } = useImageStatus(
    images,
    dangerouslyDisableLazyLoad
  );

  const ratio = presets[format]
    ? presets[format].height / presets[format].width
    : undefined;

  return (
    <ImageComponent
      ref={imageRef}
      imgProps={imgProps}
      gradient={gradient}
      cover={cover}
      appearance={appearance}
      images={images}
      ratio={ratio}
      sizes={sizes}
      alt={alt}
      status={status}
      dangerouslyDisableLazyLoad={dangerouslyDisableLazyLoad}
    />
  );
};

Image.propTypes = {
  src: PropTypes.string,
  srcset: PropTypes.shape({
    large: PropTypes.string,
    medium: PropTypes.string,
    small: PropTypes.string,
  }),
  alt: PropTypes.string,
  cover: PropTypes.bool,
  gradient: PropTypes.bool,
  format: PropTypes.oneOf(Object.keys(presets)),
  appearance: PropTypes.oneOf(["default", "full", "rounded", "full-rounded"]),
  bg: PropTypes.string,
  dangerouslyDisableLazyLoad: PropTypes.bool,
  priority: PropTypes.bool,
};

Image.defaultProps = {
  appearance: "default",
};

export default compose(
  withGenderFilter(),
  withProps((props) => {
    if (!props.currentGender) {
      return props;
    }
    // child
    if (props.currentGender === 301 && props.srcset?.childLarge) {
      return {
        ...props,
        src: props.srcset?.childLarge ?? props.src,
        srcset: {
          ...props.srcset,
          large: props.srcset?.childLarge ?? props.srcset.large,
          medium: props.srcset?.childMedium ?? props.srcset.medium,
          small: props.srcset?.childSmall ?? props.srcset.small,
        },
      };
    }
    // women
    else if (props.currentGender === 300 && props.srcset?.womenLarge) {
      return {
        ...props,
        src: props.srcset?.womenLarge ?? props.src,
        srcset: {
          ...props.srcset,
          large: props.srcset?.womenLarge ?? props.srcset.large,
          medium: props.srcset?.womenMedium ?? props.srcset.medium,
          small: props.srcset?.womenSmall ?? props.srcset.small,
        },
      };
    }
    // men
    else if (props.currentGender === 299 && props.srcset?.menLarge) {
      return {
        ...props,
        src: props.srcset?.menLarge ?? props.src,
        srcset: {
          ...props.srcset,
          large: props.srcset?.menLarge ?? props.srcset.large,
          medium: props.srcset?.menMedium ?? props.srcset.medium,
          small: props.srcset?.menSmall ?? props.srcset.small,
        },
      };
    }
  })
)(Image);
