module.exports = {
  // analytics: {
  //   enable: true,
  //   debug: process.env.NODE_ENV !== "production",
  //   defaultSettings: {},
  //   integrations: [
  //     {
  //       name: "google-tag-manager",
  //       needConsent: false,
  //       settings: {
  //         "Google Tag Manager": {
  //           containerId: "GTM-MT3G8DH",
  //         },
  //       },
  //       script: () =>
  //         require("@segment/analytics.js-integration-google-tag-manager"),
  //     },
  //     {
  //       name: "facebook-pixel",
  //       needConsent: true,
  //       settings: {
  //         "Facebook Pixel": {
  //           pixelId: "1531998887099474",
  //         },
  //       },
  //       script: () =>
  //         require("@segment/analytics.js-integration-facebook-pixel"),
  //     },
  //     {
  //       name: "google-adwords",
  //       needConsent: true,
  //       settings: {
  //         AdWords: {
  //           conversionId: "1060605264",
  //           pageRemarketing: false,
  //           eventMappings: [
  //             {
  //               value: {
  //                 label: "uAdGCKSaugIQ0Jre-QM",
  //                 eventName: "order completed",
  //               },
  //             },
  //           ],
  //         },
  //       },
  //       script: () => require("@segment/analytics.js-integration-adwords"),
  //     },
  //   ],
  // },
};
