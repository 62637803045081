import React from "react";
import classNames from "classnames";
import withFormHandlers from "../withFormHandlers";
import BaseInput from "../BaseInput";
import Label from "../../Label";

const Checkbox = (props) => {
  const {
    getErrors,
    isPristine,
    isValid,
    isDirty,
    appearance,
    inputSize,
    baseInputProps,
    help,
    label,
    ...rest
  } = props;

  if (props.type === "hidden") {
    return <input {...rest} />;
  }

  const classes = classNames("input-checkbox", {
    "input--invalid": getErrors().length,
    "input--valid": isValid(),
    [`input--${inputSize}`]: inputSize,
  });

  if (!label) {
    if (process.env.NODE_ENV === "development") {
      console.warn(
        "Usage of Checkbox without a label is deprecated and will be removed in 1.0.0. Please add a label around your checkbox and remove your form item around your checkbox."
      );
    }

    return (
      <BaseInput
        appearance={appearance}
        input={<input type="checkbox" className={classes} {...rest} />}
        help={help}
        errors={getErrors()}
        {...baseInputProps}
      />
    );
  }

  return (
    <BaseInput
      appearance={"default"}
      input={
        <div
          className={classNames("checkbox", {
            "checkbox--inverted": props.inverted,
            "checkbox--darker": props.darker,
          })}
        >
          <input type="checkbox" className={classes} {...rest} />
          <Label
            required={props.required}
            htmlFor={props.id}
            hideOptionalLabel={true}
          >
            {label}
          </Label>
        </div>
      }
      help={help}
      errors={getErrors()}
      {...baseInputProps}
    />
  );
};

export default withFormHandlers({
  getValueFromEvent: (event) => event.target.checked,
  getValueFromProps: ({ value, checked }) => {
    return typeof checked === "boolean"
      ? checked
      : typeof value === "boolean"
      ? value
      : false;
  },
  getPropsFromValue: (value) => ({ checked: value || false }),
})(Checkbox);
