import stores from "config/stores";
import { lifecycle, withProps, compose, branch } from "recompose";
import { withHideOnErrorBoundary } from "theme/components/helpers/ErrorBoundary";
import { withShop } from "web/core/shop/ShopContext";
import withGenderFilter from "theme/components/helpers/withGenderFilter";

const trackContactSubmit = (shop) => {
  if (typeof window === "undefined") {
    return;
  }
  window.dataLayer = window.dataLayer || [];
  window.dataLayer.push({
    event: "contact-form-submit",
    store: stores[shop.id].dataLayer_strore_lang,
  });
};

const trackNewsletterSubmit = (shop) => {
  if (typeof window === "undefined") {
    return;
  }
  window.dataLayer = window.dataLayer || [];
  window.dataLayer.push({
    event: "newsletter_sign_up",
    store: stores[shop.id].dataLayer_strore_lang,
  });
};

const trackRegisterSubmit = (shop) => {
  if (typeof window === "undefined") {
    return;
  }
  window.dataLayer = window.dataLayer || [];
  window.dataLayer.push({
    event: "sign_up",
    store: stores[shop.id].dataLayer_strore_lang,
  });
};

const trackLoginSubmit = (shop) => {
  if (typeof window === "undefined") {
    return;
  }
  window.dataLayer = window.dataLayer || [];
  window.dataLayer.push({
    event: "login",
    store: stores[shop.id].dataLayer_strore_lang,
  });
};

const trackAddToCart = (shop, product, qty) => {
  if (typeof window === "undefined") {
    return;
  }
  window.dataLayer = window.dataLayer || [];
  window.dataLayer.push({
    event: "add_to_cart",
    store: stores[shop.id].dataLayer_strore_lang,
    ecommerce: {
      items: [
        {
          item_id: product?.sku,
          item_name: product?.name,
          currency: product?.prices.finalPrice.priceInclTax.value.currency,
          item_brand: "Collegien",
          item_category: product?.attributeSetLabel,
          price: product?.prices.finalPrice.priceInclTax.value.amount,
          quantity: qty,
        },
      ],
    },
  });
};

const trackSearchResult = (shop, search) => {
  if (typeof window === "undefined") {
    return;
  }
  window.dataLayer = window.dataLayer || [];
  window.dataLayer.push({
    event: "view_search_results",
    store: stores[shop.id].dataLayer_strore_lang,
    search_term: search,
  });
};

const trackProductPage = () =>
  compose(
    withHideOnErrorBoundary,
    withShop(),
    withProps((props) => {
      return {
        track: {
          event: "view_item",
          store: stores[props.shop.id].dataLayer_strore_lang,
          ecommerce: {
            items: [
              {
                item_id: props.product?.sku,
                item_name: props.product?.name,
                currency:
                  props.product?.prices.finalPrice.priceInclTax.value.currency,
                item_brand: "Collegien",
                item_category: props.product?.attributeSetLabel,
                price:
                  props.product?.prices.finalPrice.priceInclTax.value.amount,
              },
            ],
          },
        },
      };
    }),
    lifecycle({
      componentDidMount: function () {
        if (typeof window === "undefined") {
          return;
        }
        window.dataLayer = window.dataLayer || [];
        window.dataLayer.push({
          ...this.props.track,
        });
      },
      componentDidUpdate: function (prevProps) {
        if (typeof window === "undefined") {
          return;
        }

        if (prevProps.product?.sku !== this.props.product?.sku) {
          window.dataLayer = window.dataLayer || [];
          window.dataLayer.push({
            ...this.props.track,
          });
        }
      },
    })
  );

const trackCartPage = () =>
  compose(
    withHideOnErrorBoundary,
    withShop(),
    withProps((props) => {
      return {
        track: {
          event: "view_cart",
          id: props.cart?.id,
          store: stores[props.shop.id].dataLayer_strore_lang,
          cart_total: props.cart?.totals?.totalInclTax?.value.amount,
          ecommerce: {
            items: props.cart.items.map((item) => {
              return {
                item_id: item.sku,
                item_name: item.name,
                currency: item?.priceInfo?.rowTotalInclTax?.value.currency,
                item_brand: "Collegien",
                item_category: item.product.attributeSetLabel,
                price: item?.priceInfo?.rowTotalInclTax?.value.amount,
                quantity: item.qty,
              };
            }),
          },
        },
      };
    }),
    lifecycle({
      componentDidMount: function () {
        if (typeof window === "undefined") {
          return;
        }
        window.dataLayer = window.dataLayer || [];
        window.dataLayer.push({
          ...this.props.track,
        });
      },
    })
  );

const trackGlobalPage = (pageType = null) =>
  compose(
    withHideOnErrorBoundary,
    withGenderFilter(),
    withShop(),
    withProps((props) => {
      return {
        track: {
          event: "page_view",
          store: stores[props.shop.id].dataLayer_strore_lang,
          page_template: pageType ?? "other",
          page_univers: props.currentGenderLabel ?? "other",
          page_category:
            props.category?.name ?? props.product?.attributeSetLabel ?? "other",
        },
      };
    }),
    lifecycle({
      componentDidMount: function () {
        if (typeof window === "undefined") {
          return;
        }
        window.dataLayer = window.dataLayer || [];
        window.dataLayer.push({
          ...this.props.track,
          page_url: window.location.href,
        });
      },
      componentDidUpdate: function (prevProps) {
        if (typeof window === "undefined") {
          return;
        }
        if (
          prevProps.currentGenderLabel !== this.props.currentGenderLabel ||
          prevProps.category?.name !== this.props.category?.name
        ) {
          window.dataLayer = window.dataLayer || [];
          window.dataLayer.push({
            ...this.props.track,
            page_url: window.location.href,
          });
        }
      },
    })
  );

const trackUser = () =>
  compose(
    withHideOnErrorBoundary,
    branch(
      (props) => (props.me.me && props.me.me.id) || (props.me && props.me.id),
      compose(
        withProps((props) => {
          const address = props.me.me?.addresses?.find(
            (addr) => addr.default_shipping === true
          );
          return {
            track: {
              event: "user_data",
              user_email: props.me.me?.email || props.me.email,
              user_lastname: props.me.me?.lastname || props.me.lastname,
              user_firstname: props.me.me?.firstname || props.me.firstname,
              user_phone: address?.telephone || "",
              user_city: address?.city || "",
              user_postcode: address?.postcode || "",
              user_country: address?.country_id || "",
              user_is_new_customer: address ? false : true,
            },
          };
        }),
        lifecycle({
          componentDidMount: function () {
            if (typeof window === "undefined") {
              return;
            }
            if (window.dataLayer.find((item) => item.event === "user_data")) {
              return;
            }

            window.dataLayer = window.dataLayer || [];
            window.dataLayer.push({
              ...this.props.track,
            });
          },
          componentDidUpdate: function () {
            if (typeof window === "undefined") {
              return;
            }
            if (window.dataLayer.find((item) => item.event === "user_data")) {
              return;
            }

            window.dataLayer = window.dataLayer || [];
            window.dataLayer.push({
              ...this.props.track,
            });
          },
        })
      ),
      (BaseComponent) => BaseComponent
    )
  );

const trackCheckout = () =>
  compose(
    withHideOnErrorBoundary,
    withShop(),
    branch(
      (props) => props.cart,
      (BaseComponent) =>
        compose(
          withProps((props) => {
            return {
              track: {
                store: stores[props.shop.id].dataLayer_strore_lang,
                ecommerce: {
                  items: props.cart.items.map((item) => {
                    return {
                      item_id: item.sku,
                      item_name: item.name,
                      currency:
                        item.priceInfo.price.priceInclTax.value.currency,
                      item_brand: "Collegien",
                      item_category: item.product.attributeSetLabel,
                      price: item.priceInfo.price.priceInclTax.value.amount,
                      quantity: item.qty,
                    };
                  }),
                },
              },
            };
          }),
          lifecycle({
            componentDidMount: function () {
              if (typeof window === "undefined") {
                return;
              }
              window.dataLayer = window.dataLayer || [];
              window.dataLayer.push({
                event: "begin_checkout",
                cart_total: this.props.cart.totals.totalInclTax.value.amount,
                ...this.props.track,
              });
            },
            componentDidUpdate: function (prevProps) {
              if (typeof window === "undefined") {
                return;
              }
              if (
                prevProps.currentStepNumber === 0 &&
                this.props.currentStepNumber === 1
              ) {
                window.dataLayer = window.dataLayer || [];
                window.dataLayer.push({
                  event: "add_shipping_info",
                  ...this.props.track,
                });
              } else if (
                prevProps.currentStepNumber === 1 &&
                this.props.currentStepNumber === 2
              ) {
                window.dataLayer = window.dataLayer || [];
                window.dataLayer.push({
                  event: "add_payment_info",
                  ...this.props.track,
                });
              }
            },
          })
        )(BaseComponent),
      (BaseComponent) => BaseComponent
    )
  );

const trackOrderSuccess = () =>
  compose(
    withHideOnErrorBoundary,
    withShop(),
    withProps((props) => {
      const items = props.order.items.map((item) => {
        return {
          item_id: item.sku,
          item_name: item.name,
          currency: item.row_total.priceInclTax.value.currency,
          item_brand: "Collegien",
          item_category: item.product.attributeSetLabel,
          price: item.row_total.priceInclTax.value.amount,
          quantity: item.qty_ordered,
        };
      });
      return {
        track: {
          event: "purchase",
          store: stores[props.shop.id].dataLayer_strore_lang,
          ecommerce: {
            payment_type: props.order.payments[0]?.code,
            shipping_tier: props.order.shipping_method_title,
            transaction_id: props.order.order_id,
            value: props.order.totals.totalInclTax.value.amount,
            tax: props.order.totals.tax.value.amount,
            shipping: props.order.totals.shippingPriceInclTax.value.amount,
            currency: props.order.totals.totalInclTax.value.currency,
            coupon: props.order.coupon_code ?? "",
            items,
          },
        },
      };
    }),
    lifecycle({
      componentDidMount: function () {
        if (typeof window === "undefined") {
          return;
        }

        if (localStorage.getItem("purchase") !== this.props.orderId) {
          localStorage.setItem("purchase", this.props.orderId);
          window.dataLayer = window.dataLayer || [];
          window.dataLayer.push({
            ...this.props.track,
          });
        }
      },
    })
  );
export {
  trackContactSubmit,
  trackNewsletterSubmit,
  trackRegisterSubmit,
  trackLoginSubmit,
  trackSearchResult,
  trackAddToCart,
  trackProductPage,
  trackCartPage,
  trackUser,
  trackGlobalPage,
  trackCheckout,
  trackOrderSuccess,
};
