import React from "react";
import PropTypes from "prop-types";
import MediaQuery from "theme/components/helpers/MediaQuery";
import Breakpoints from "theme/components/atoms/Breakpoints";

const IsDesktop = ({ children }) => (
  <MediaQuery minWidth={Breakpoints.lg}>
    {(isDesktop) =>
      typeof children === "function"
        ? children(isDesktop)
        : isDesktop && children
    }
  </MediaQuery>
);

IsDesktop.propTypes = {
  children: PropTypes.oneOfType([
    PropTypes.node.isRequired,
    PropTypes.func.isRequired,
  ]),
};

export default IsDesktop;
