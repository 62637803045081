import React from "react";
import PropTypes from "prop-types";
import Label from "../../Label";

// /!\ Do not use the radio as a standalone
// Refer to RadioGroup in order to use the Radio input
// /!\ Do not use the `Input` Component here since the form handlers
// are already handled by `RadioGroup`

const Radio = ({ label, ...props }) => {
  return (
    <div className="radio">
      <input {...props} type="radio" />
      {props.required ? (
        <Label htmlFor={props.id} required>
          {label}
        </Label>
      ) : (
        <Label htmlFor={props.id}>{label}</Label>
      )}
    </div>
  );
};

Radio.propTypes = {
  value: PropTypes.oneOfType([PropTypes.string, PropTypes.number]).isRequired,
};

export default Radio;
