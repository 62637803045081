import { withCookies } from "react-cookie";
import { compose, withProps, withHandlers, withState } from "recompose";
import stores from "config/stores";
import { graphql } from "react-apollo";
import ShopQuery from "./ShopQuery.gql";
import { withApollo } from "react-apollo";

const withGenderFilter = () =>
  compose(
    withApollo,
    withCookies,
    withState("loadingGender", "setLoadingGender", null),
    graphql(ShopQuery, {
      props: ({ data }) => {
        return {
          shop: data.loading || data.error ? null : data.shop,
          loading: data.loading,
        };
      },
    }),
    withHandlers({
      setGender:
        (props) => (genderCodeParam, genderLabelParam, genders, callback) => {
          let genderCode = genderCodeParam;
          if (!genderCodeParam && genderLabelParam) {
            genderCode = genders?.find(
              (gender) => gender.label === genderLabelParam
            )?.code;
          }
          props.cookies.set("collegien_gender", Number(genderCode), {
            path: "/",
            maxAge: "86400", // 1 day
          });
          if (callback) {
            return callback();
          }
        },
      resetGenderFilter: (props) => () => {
        props.setLoadingGender(props.cookies.get("collegien_gender"));
        props.cookies.remove("collegien_gender", {
          path: "/",
        });
        if (typeof window !== "undefined" && window.apolloClient) {
          window.apolloClient
            .reFetchObservableQueries()
            .then((_) => props.setLoadingGender(null));
        } else {
          props.setLoadingGender(null);
        }
      },
    }),
    withProps((props) => {
      const genders = !props.loading
        ? stores[props.shop.id].gender_filter_values
        : [
            { code: 299, label: "homme" },
            { code: 300, label: "femme" },
            { code: 301, label: "enfant" },
          ];
      return {
        genders,
        genderFilterName: !props.loading
          ? stores[props.shop.id]?.gender_filter_name
          : null,
        isChildGender: Number(props.cookies.get("collegien_gender")) === 301,
        isAdultGender:
          Number(props.cookies.get("collegien_gender")) === 300 ||
          Number(props.cookies.get("collegien_gender")) === 299,
        currentGender: props.cookies.get("collegien_gender")
          ? Number(props.cookies.get("collegien_gender"))
          : null,
        currentGenderLabel: genders.find(
          (gender) =>
            gender.code === Number(props.cookies.get("collegien_gender"))
        )?.label,
      };
    })
  );

export default withGenderFilter;
