import React from "react";
import PropTypes from "prop-types";
import classnames from "classnames";
import compose from "recompose/compose";
import withProps from "recompose/withProps";
import withFormHandlers from "theme/components/atoms/Form/Input/withFormHandlers";
import { FormattedMessage } from "react-intl";
import ReactSelect, { components } from "react-select";
import BaseInput from "../BaseInput";
import Icon from "theme/components/atoms/Icon";

const DropdownIndicator = (props) => {
  return props.isFocused ? props.iconOpen : props.icon;
};

const findValueInOptions = (val, options) =>
  options.find((option) => option.value === val);

const defaultRenderOptions = (options) => {
  return options.map((option) =>
    Object.assign({}, option, {
      className: "select__option",
    })
  );
};

const Select = (props) => {
  const errors = props.getErrors();

  const classes = classnames("react-select", {
    invalid: !props.isPristine() && !props.isValid(),
    valid: !props.isPristine() && props.isValid(),
    "select--light": props.light,
    "select--big": props.big,
    "select--placeholder": !props.value && props.placeholder,
  });

  const value = props.value;
  const selectedOption =
    findValueInOptions(value, props.options) ||
    (!props.placeholder && props.options[0]);

  const options =
    typeof props.renderOptions === "function"
      ? props.renderOptions(props.options)
      : defaultRenderOptions(props.options);

  return (
    <BaseInput
      input={
        <ReactSelect
          className={classes}
          classNamePrefix="select"
          name={props.name}
          id={props.id}
          instanceId={props.id}
          placeholder={props.placeholder}
          value={selectedOption}
          onChange={(option) =>
            props.onChange(option && option.value ? option.value : null)
          }
          noOptionsMessage={() => {
            return (
              <FormattedMessage
                id="atoms.Form.Input.select.noResults"
                defaultMessage="Aucun résultat"
              />
            );
          }}
          options={options}
          isSearchable={props.isSearchable}
          required={props.required}
          isOptionDisabled={(option) => option.disabled}
          components={{
            DropdownIndicator: () => <DropdownIndicator {...props} />,
            ClearIndicator: ({ innerProps: { ref, ...props } }) => (
              <div {...props} ref={ref} className="select-clear">
                <Icon icon="close" title="close select" />
              </div>
            ),
            Input: (props) => (
              <components.Input {...props} autoComplete="invalid" />
            ),
            ValueContainer: (ownProps) => (
              <components.ValueContainer {...ownProps}>
                {ownProps.children}
              </components.ValueContainer>
            ),
          }}
        />
      }
      help={props.help}
      errors={errors}
      {...props.baseInputProps}
    />
  );
};

Select.propTypes = {
  name: PropTypes.string.isRequired,
  placeholder: PropTypes.oneOfType([PropTypes.node, PropTypes.string]),
  id: PropTypes.string.isRequired,
  options: PropTypes.arrayOf(
    PropTypes.shape({
      value: PropTypes.oneOfType([PropTypes.string, PropTypes.number])
        .isRequired,
      label: PropTypes.oneOfType([PropTypes.string, PropTypes.node]).isRequired,
    }).isRequired
  ),
  isSearchable: PropTypes.bool,
  required: PropTypes.bool,
  light: PropTypes.bool,
  big: PropTypes.bool,
  icon: PropTypes.node,
  iconOpen: PropTypes.node,
};

Select.defaultProps = {
  isSearchable: false,
  light: false,
  big: false,
  icon: <Icon icon="chevron" size="tiny" title="select" />,
  iconOpen: <Icon icon="chevron-up" title="select" />,
};

export default compose(
  withProps(() => ({
    validationError: (
      <FormattedMessage
        id="components.atoms.Form.Input.Select.requiredError"
        defaultMessage="You must select an option"
      />
    ),
  })),
  withFormHandlers({
    dirtyOnChange: true,
    getValueFromEvent: (value) => value,
    getValueFromProps: (props) => props.value,
  })
)(Select);
